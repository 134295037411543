.icon_bekreftet path{
    stroke: rgb(148, 205, 116);
    color:rgb(148, 205, 116)
}

.icon_ikkebekreftet path{
    stroke: rgb(100,149,237);
}

.icon_kansellert path{
    stroke: rgb(255,0,0);
}