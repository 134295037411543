.carousel-caption{
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
}

.item{
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.icon_ikkehentet path{
    stroke: rgb(255,140,0);
}

.icon_hentet path{
    stroke: rgb(100,149,237);
}

.icon_levert path{
    stroke: rgb(148, 205, 116);
}